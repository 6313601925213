import apiClient from '../apiClient';

export async function registerCustomMetric(payload) {
  try {
    const request = await apiClient.post('/metric/register', payload);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function getCustomMetric(identity) {
  try {
    const request = await apiClient.get(`/metric/${identity}`);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function registerSpendMetrics(payload) {
  try {
    const request = await apiClient.post('/metric/spend/register', payload);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function getSpendMetrics(identity) {
  try {
    const request = await apiClient.get(`/metric/spend/${identity}`);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function registerBookingMetrics(payload) {
  try {
    const request = await apiClient.post('/metric/booking/register', payload);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function getBookingMetrics(identity) {
  try {
    const request = await apiClient.get(`/metric/booking/${identity}`);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function registerAuthenticationMetrics(payload) {
  try {
    const request = await apiClient.post('/metric/authentication/register', payload);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function getAuthenticationMetrics(identity) {
  try {
    const request = await apiClient.get(`/metric/authentication/${identity}`);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function registerApiMetrics(payload) {
  try {
    const request = await apiClient.post('/metric/api/register', payload);
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function getApiMetrics(identity) {
  try {
    const request = await apiClient.get(`/metric/api/${identity}`);
    return request;
  } catch (error) {
    return error.request;
  }
}
