import moment from 'moment-timezone';

// Function to get the date a number of days behind the current date
// Time is 00:00:00

// If time = true, then the time is set to 23:59:59

export function getDateNumberOfDaysBehind(days, time = false) {
  const today = moment.tz('Europe/London');
  const date = today.clone().subtract(days, 'days').startOf('day');
  if (time) {
    return date.set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DDTHH:mm:ss');
  }
  return date.format('YYYY-MM-DDT00:00:00');
}
