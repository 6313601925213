import React from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';

// API
import { getDynamicCharts } from 'ajax/dashboard/helper';
// Charts
import SpendingChart from 'components/Charts/AllSpendingCharts';

function ChartsPage() {
  const { data: dynamicCharts, isLoading } = useQuery(
    ['all_charts'],
    async () => {
      const req = await getDynamicCharts();
      if (req.status === 200) return req.data;
      if (req.data.type === 'Validation') {
        req.data.data.map((error) => toast.error(error.message));
        return [];
      }
      toast.error(req.data.message);
      return [];
    },
    {
      enabled: true,
      refetchOnWindowFocus: false
    }
  );

  return (
    <div>
      <h2 className="h3-mobile md:h3-desktop mb-4 border-b border-b-gray-02 pb-6 md:mb-6">Dashboard</h2>
      <div className="relative grid grid-cols-1 xl:grid-cols-2 gap-3">
        {isLoading && <Skeleton count={4} className="mb-1 h-8" containerClassName="block mb-8" />}

        {dynamicCharts?.map((chart, index) => (
          <SpendingChart key={index} dynamicChartProp={chart} />
        ))}
      </div>
    </div>
  );
}

export default ChartsPage;
