export function getMonthStartEndDates() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  // Create the first day of the month at UTC time
  const startDate = new Date(Date.UTC(year, month, 1));

  // Get the number of days in the month at UTC time
  const daysInMonth = new Date(Date.UTC(year, month + 1, 0)).getDate();

  // Create the last day of the month at UTC time
  const endDate = new Date(Date.UTC(year, month, daysInMonth));

  // Set the time to the beginning and end of the day at UTC time
  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(23, 59, 59, 999);

  // Convert to ISO format without milliseconds
  const startDateFormatted = startDate.toISOString().slice(0, 19);
  const endDateFormatted = endDate.toISOString().slice(0, 19);

  return {
    startDate: startDateFormatted,
    endDate: endDateFormatted
  };
}

export function getLastYearDatesIso() {
  const today = new Date();
  const lastYear = today.getFullYear() - 1;

  // Set the first date to January 1st of the last year at UTC
  const firstDate = new Date(Date.UTC(lastYear, 0, 1));

  // Set the last date to December 31st of the last year at UTC, with time set to 23:59:59
  const lastDate = new Date(Date.UTC(lastYear, 11, 31, 23, 59, 59));

  firstDate.setUTCHours(0, 0, 0, 0);
  lastDate.setUTCHours(23, 59, 59, 999);

  const startDateFormatted = firstDate.toISOString().slice(0, 19);
  const endDateFormatted = lastDate.toISOString().slice(0, 19);

  return { startDate: startDateFormatted, endDate: endDateFormatted };
}

export function getLastWeekDatesIso() {
  const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - 0, Monday - 1, etc.
  const diffToLastMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // If today is Sunday, last Monday was 6 days ago, otherwise calculate difference to last Monday

  // Calculate last Monday date by subtracting diffToLastMonday days from today
  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - diffToLastMonday - 7); // Subtract 7 more days to go to the previous week
  lastMonday.setUTCHours(0, 0, 0, 0);

  // Calculate next Sunday date by adding 6 days to last Monday
  const nextSunday = new Date(lastMonday);
  nextSunday.setDate(lastMonday.getDate() + 6);
  nextSunday.setUTCHours(23, 59, 59, 999);

  const startDateFormatted = lastMonday.toISOString().slice(0, 19);
  const endDateFormatted = nextSunday.toISOString().slice(0, 19);

  return { startDate: startDateFormatted, endDate: endDateFormatted };
}

export function getLastMonthDatesIso() {
  const today = new Date();
  const year = today.getFullYear();
  const lastMonth = today.getMonth() - 1;

  // Set the first date to January 1st of the last year at UTC
  const firstDate = new Date(Date.UTC(year, lastMonth, 1));

  // Set the last date to December 31st of the last year at UTC, with time set to 23:59:59
  const lastDate = new Date(Date.UTC(year, lastMonth, 31, 23, 59, 59));

  firstDate.setUTCHours(0, 0, 0, 0);
  lastDate.setUTCHours(23, 59, 59, 999);

  const startDateFormatted = firstDate.toISOString().slice(0, 19);
  const endDateFormatted = lastDate.toISOString().slice(0, 19);

  return { startDate: startDateFormatted, endDate: endDateFormatted };
}

export function getYearStartEndDates() {
  const now = new Date();
  const year = now.getFullYear();

  // Create the first day of the year at UTC time
  const startDate = new Date(Date.UTC(year, 0, 1));

  // Create the last day of the year at UTC time
  const endDate = new Date(Date.UTC(year, 11, 31));

  // Set the time to the beginning and end of the day at UTC time
  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(23, 59, 59, 999);

  // Convert to ISO format without milliseconds
  const startDateFormatted = startDate.toISOString().slice(0, 19);
  const endDateFormatted = endDate.toISOString().slice(0, 19);

  return {
    startDate: startDateFormatted,
    endDate: endDateFormatted
  };
}
